.aboutScreen{
    padding-top: 60px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    align-items: center;
    background-color: white;
}

.aboutScreenContainer{
    width: 100%;
    /* max-width: 1440px; */
    display: flex;
    flex-direction: column;    
    gap: 75px;
    align-items: center;
}

.aboutSection1{
    width: 90%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.aboutSec1Column{
    display: flex;
    flex-direction: column;
    text-align: left;
    color: black;
    gap: 38px;
}

.aboutSec1Title{
    font-weight: 300;
    font-size: 90px;
}

.aboutSec1Body{
    font-weight: 300;
    font-size: 22px;
    max-width: 633px;
}

.aboutSec1Img{
    height: 525px;
}



.aboutMainTitle{
    font-weight: 600;
    font-size: 50px;
    width: 80%;
}

.aboutSubTitle{
    font-weight: 600;
    font-size: 40px;
}


.aboutSubText{
    font-weight: 400;
    font-size: 16px;
    color: #858797;
}

.aboutStatsRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
}

.aboutStatsColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutPlus{
    color: #63CFE5;
}

.aboutSection2{
    
    width: 90%;
    max-width: 1440px;
    margin-top: 30px;
    margin-bottom: 300px;
}

.aboutTeamRow{
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.aboutTeamMember{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.memberImg{
    border-radius: 50%;
    height: 214px;
    width: 214px;
    margin-bottom: 30px;
    margin-top: 50px;
}




@media (max-width:900px) {

    .aboutSection1{flex-direction: column;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .aboutSec1Img{width: 100%; height: auto;}

    .aboutTeamRow{flex-direction: column; gap: 0px;}

    .aboutSec1Title{font-size: 37px;}
    .aboutSec1Body{font-size: 18px;}

    .aboutSection2{margin-bottom: 30px;}
}