.featureScreen{
    /* padding: 60px; */
    display: flex;
    flex-direction: column;    
    gap: 30px;
    position: relative;
    align-items: center;
}

.featureScreenContainer{
    width: 100%;
    
    display: flex;
    flex-direction: column;    
    align-items: center;
    color: #28527A;
}




.featuresScreenImg1
{
    height: 486px;
    width: 357px;
    background-color: gray;
}


.featureScreenContact{
    margin-top: 74px;

    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
}

.featureSecContent{
    display: flex;
    justify-content: space-between;
    width: 1450px;
}
.featureSecContent.column{
    flex-direction: column;
}

.featureScreenSec1{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.featureScreenSec1Content{
    width: 950px;
}

.featureScreenSec2{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    min-height: 823px;
    background-color: #BDEAE8;

    display: flex;
    flex-direction: row;
    gap: 125px;
    justify-content: center;
    align-items: center;
    margin-top: 62px;
}

.featureSec2Column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 85px;
}

.featureTextColumn{
    max-width: 710px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 16px;

    margin: 20px;
    font-weight: 300;
    font-size: 39px;
}

.featureTextTitle{
    font-weight: 400;
    font-size: 72px;
}

.featuresScreenImg2{
    height: 42px;
}


.featuresScreenImg3{
    height: 425px;
    width: 320px;
    background-color: gray;
    margin-bottom: 30px;
}



.featureScreenSec3{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    min-height: 818px;
    background-color: white;

    display: flex;
    flex-direction: row;
    gap: 91px;
    justify-content: center;
    align-items: center;
}



.featuresScreenImg4{
    height: 634px;
    width: 423px;
    background-color: gray;
}


.featureScreenSec4{

    width: 100%;
    height: 392px;
    padding: 50px;
    background-color: #BDEAE8;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-size: 50px;
    font-weight: 300;
}   

.featuresSec4Title{
    font-weight: 500;
}




.featureScreenSec5{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    min-height: 800px;
    background-color: white;

    display: flex;
    flex-direction: row;
    gap: 165px;
    justify-content: center;
    align-items: center;
}


.featuresScreenImg5{
    margin: 30px;
    height: 380px;
}

.featureSceenSec6
{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 37px;
    margin-bottom: 100px;
}


@media (max-width:1540px) {

    .featureSecContent{width: 1230px; align-items: center;}
    .featureScreenSec1Content{width: 700px;}
}

@media (max-width:1280px) {

    .featureSecContent{width: 1000px; }
    .featureScreenSec1Content{width: 500px;}
    .homeScreenTitles{font-size: 60px;}
}

@media (max-width:1030px) {

    .featureSecContent{width: 750px; align-items: center;}
    .featureTextTitle{font-size: 50px;}
    .featureTextColumn{font-size: 30px; width: 300px;}
    .featuresScreenImg4{width: auto; height: 400px;}

    .featureScreenContact{
        align-items: flex-start;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}


@media (max-width:900px) {


    .featureSecContent{flex-direction: column; width: 100%;}
    .featureScreenSec1Content{width: 80%;}
    .featureScreenContact{flex-direction: column; width: 100%;}
    .featuresScreenImg1{display: none;}

    .featureScreenSec2{flex-direction: column;}
    .featureSec2Column{gap: 10px;}
    .featureSec2Column{margin: 20px;}
    

    
    .featureScreenSec3{flex-direction: column-reverse; gap: 20px;}
    .featuresScreenImg4{width: 330px; margin-bottom: 30px; height: auto; margin-top: 20px;}


    .featureScreenSec4{font-size: 33px;}
    .featuresSec4Title{font-size: 37px;}

    .featureScreenSec5{flex-direction: column-reverse; gap: 20px;}
    .featureTextColumn{margin: 30px; font-size: 27px; margin-left: 0px; margin-right: 0px;}

    .featureSceenSec6{flex-direction: column; align-items: center;}
    .featureTextTitle{font-size: 37px;}

    

    .featureScreenSec4{height: auto; box-sizing: border-box;}
}