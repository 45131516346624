.header{
   
    
    min-height: 104px;
    
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px;
    border-color: #0000001A;
    border-style: solid;
    display: flex;
    justify-content: center;
}

.headerRow{
    width: 100%;
    max-width: 1440px;
    min-height: 104px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.routesRow{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}

.logoHeader{
    margin-left: 20px;
    margin-right: 8px;
    height: 28px;
    width: 116px;
    cursor: pointer;
}

.headerImg{
    cursor: pointer;
    visibility: hidden;
}

.headerRoutesRow{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}


.headerRoute{
    color: #858797;
    font-weight: 500;
    font-size: 17px;
    transition: all 0.2s;
    cursor: pointer;
}

.headerRoute.selected{
    color: black;
    
}

.headerRoute:hover{
    color: #01A4BD;
}


.buttonsRowHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.iconHeader{
    height: 34px;
    width: 34px;
}

.verticalLine{
    height: 40px;
    width: 1px;
    background-color: #0000001A;
}

.headerBtn{
    height: 45px;
    width: 117px;
    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    transition: all 0.2s;
    cursor: pointer;
}

.headerBtn.login{
    background-color: white;
    color: #01A4BD;
    border-color: #01A4BD;
}

.headerBtn.login:hover{
    background-color: #01A4BD;
    color: white;
    border-color: transparent;
}


.headerBtn.join{
    background-color: #01A4BD;
    color: white;
    border-color: transparent;
}


.headerBtn.join:hover{
    background-color: white;
    color: #01A4BD;
    border-color: #01A4BD;
}




.headerProfile{
    display: flex;
    flex-direction: column;
}

.headerProfileTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    font-weight: 500;
    font-size: 18px;

    cursor: pointer;
}

.headerProfilePic{
    height: 42px;
    width: 42px;
    border-radius: 50%;

    background-color: #01A4BD;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerProfileDown{
    height: 16px;
    width: 16px;
}


.headerProfileOptions{
    position: absolute;
    top: 80px;
    width: 250px;
    height: 870px;
    padding-top: 15px;
    padding-left: 20px;

    /* border: 1px;
    border-top: 0px;
    border-radius: 5px;
    border-color: black;
    border-style: solid; */
    

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -20px -20px;
    background-size: 100%;
    

    
    gap: 15px;
    /* background-image: url("speechBubble.png"); */
    /* background-image: url('http://www.birds.com/wp-content/uploads/home/bird4.jpg'); */

    /* background-color: white; */

    transition: all 0.3s;
}

.headerProfileOption{

    color: #858797;
    font-weight: 500;
    cursor: pointer;
}

.headerProfileOption:hover{
    text-decoration: underline;
}






@media (max-width:900px) {

    .headerRow{
        padding-left: 20px;
        padding-right: 20px;
    }

    .headerImg{
        visibility: visible; 
        margin-right: 20px;
        height: 20px;
        width: 25px;
        background: url(../../public/assets/headerBurger.png);
        background-size: contain;

        -webkit-transition: all .15s ease-in-out;
        -moz-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
    }

    .headerImg.open{
        height: 20px;
        width: 20px;
        background: url(../../public/assets/headerCancel.png);
        background-size: contain;
    }


    .headerRoutesRow.routesClosed{visibility: hidden; z-index: -40;}

    .headerRoutesRow{
        position: absolute;
        top: 105px;
        left: 0px;
        right: 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 52px;
        background-color: white;
        z-index: 40;
        padding: 50px;

        border-bottom: 1px solid #858797;
        
    }



    .headerRoute{
        font-weight: 300;
        font-size: 30px;
        color: black;
    }


}