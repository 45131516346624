.homeScreen{
    
    display: flex;
    flex-direction: column;    
    gap: 30px;
    align-items: center;
    position: relative;
    background-color: white;
}

.homeScreenContainer{
    width: 100%;
    display: flex;
    flex-direction: column;    
    align-items: center;
    

    color: black;
}



.homeScreenSec1{
    margin-top: 42px;
    max-width: 1440px;
    width: 100%;
    min-height:803px;


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;

    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    
}

.homeScreenImg1{
    margin-left: 20px;
    height: 803px;
    width: 536px;
    z-index: 20;
}

.homeScreenSec1Content{
    
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.homeScreenTitles{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-style: normal;
    font-size: 70px;
    font-weight: 300;
    text-align: left;
}

.homeScreenBold{
    font-weight: 400;
}

.homeScreenSubtitle{
    margin-top: 20px;
    max-width: 458px;
    color: #000;
    font-size: 22px;
    font-weight: 300;
    text-align: left;
}

.homeScreenContact{
    margin-top: 92px;

    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
}


.homeScreenContactInput{
    width: 315px;
    height: 51px;

    color: #000;
    font-size: 17px;
    font-weight: 300;

    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
} 

.homeScreenContactInput:focus {
    outline-color: transparent;
    outline-style: none;
  }



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}





.homeScreenContactBtn{
    height: 51px;
    width: 154px;
    border-radius: 12px;


    background-color: #01A4BD;
    border-color: transparent;
    color: white;

    font-size: 17px;
    font-weight: 500;

    cursor: pointer;
}



.homeScreenContactBtn:disabled{
    background-color: #9C9C9C;
    cursor: auto;
}


.homeScreenSec2{
    width: 100%;
    margin-top: -50px;
    margin-bottom: -50px;
    background-color: #BDEAE8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.homeScreenSec2Content{
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin: 175px;
    color: #28527A;
    font-size: 50px;
    font-weight: 300;

    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;
}


.homeScreenSec3{
    max-width: 1440px;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    

    font-size: 25px;
    font-weight: 400;
    color: #28527A;
}

.homeScreenSec3Content{
    width: 100%;
    margin-right: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;

    font-size: 25px;
    font-weight: 400;
    color: #28527A;

    text-align: left;
    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;
}



.youtubeHS{
    height: 590px;
    width: 1029px;
}


.homeScreenSec3Btn{
    height: 50px;
    width: 160px;
    border-radius: 12px;

    background-color: #01A4BD;
    border-color: transparent;
    color: white;

    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}


.homeScreenSec4{
    max-width: 1440px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 63px;
    margin-top: 153px;

    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;
}

.homeScreenImg2{
    /* height: 526px; */
    width: 520px;
}


.homeScreenSec4Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin-top: 63px;
    
}


.homeScreenSec4Titles{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-size: 70px;
    font-weight: 300;
}

.homeScreenExploreFeatures{
    cursor: pointer;
    text-decoration: underline;
    font-weight: 300;
    font-size: 30px;
}


.homeScreenSec5{
    margin-top: 93px;
    max-width: 1440px;
    width: 100%;
    

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;

    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;
}



.homeScreenStep{
    width: 365px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hssNumber{
    font-weight: 400;
    font-size: 30px;
}

.hssLine{
    height: 2px;
    width: 100%;
    background-color: black;
    margin-top: 10px;
}

.hssTitle{
    margin-top: 33px;
    font-size: 26px;
    font-weight: 400;
    text-align: left;
}

.hssContent{
    margin-top: 28px;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
}

.homeScreenSec6{
    margin-top: 100px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 55px;

    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;
}


.homeScreenSec6Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}

.homeScreenSec6Titles{
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #120B46;
    font-size: 59px;
    font-weight: 600;
}

.homeScreenSec6Subtitle{
    font-weight: 400;
    font-size: 18px;
    color: #6D678E;
}


.homeScreenPlanRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 43px;
}

.planTileSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    /* width: 100%; */
    
    
}

.planTile{
    /* height: 360px; */
    
    /* max-width: 592px; */
    border-radius: 8px;
    background-color: #D6F2FF;
    color: #120B46;
    padding: 25px 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 34px;
    
    
    
}

.planTile.Professional{
    background-color: #BDEAE8;
}

.planTileContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
}

.planTileLeft{
    max-width: 266px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 9px;

    font-weight: 500;
    font-size: 19px;
}

.planTileTitle{
    height: 39px;
    width: 128px;

    border-radius: 6px;
    border-color: #120B46;
    border-style: solid;
    

    font-weight: 600;
    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.planTileCost{
    font-weight: 600;
    font-size: 80px;
    margin-top: 10px;
}


.planTileLine{
    margin-left: 10px;
    margin-right: 36px;
    height: 203px;
    width: 1px;
    background-color: #9C9C9C;
}

.planTileRight{
    display: flex;
    flex-direction: column;
    gap: 31px;
    margin-right: 20px;
    
    text-align: left;
    
}

.planTileRow{
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;

    font-weight: 400;
    font-size: 16.5px;
}

.planTileCheck{
    height: 15px;
    width: 15px;
}

.planTileBtn{
    width: 100%;
    height: 61px;

    background-color: #120B46;
    color: white;
    border-radius: 10px;

    font-weight: 400;
    font-size: 18px;

    cursor: pointer;
}



.homeScreenSec7{
    margin-top: 27px;
    width: 100%;
    height: 580px;
    display: flex;
    color: #28527A;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #BDEAE8;
    gap: 63px;
}

.homeScreenSec7Titles{
    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 50px;
}

.homeScreenSec7Btn{
    height: 83px;
    width: 374px;
    border-color: #28527A;
    border-radius: 12px;
    border-style: solid;

    color: #28527A;
    font-weight: 300;
    font-size: 34px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: underline;
}

.homeScreenSec7Btn:hover{
    background-color: #28527A;
    color: white;
    text-decoration: none;
}


.buyClassesTitle{
    font-size: 59px;
    font-weight: 700;
    color: #120B46;
}


.buyClassesSubtitle{
    font-size: 18px;
    font-weight: 400;
    color: #6D678E;
    margin-top: 25px;
    margin-bottom: 33px;
}

.homeScreenSec3Mobile{display: none;}

.homeTitleText{
    width: 718px;
}

.videoContainer{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.video110{
    min-width: 60%;
    min-height: 460px;
}

.test110{
    height: 30px;
    
}

@media (max-width:750px) {
    .homeTitleText{width: auto;}
}

@media (max-width:1540px) {

    .homeScreenSec1{width: 1250px;}
}


@media (max-width:1280px) {

    .homeScreenSec1{width: 1000px;}
}


@media (max-width:1030px) {

    .homeScreenSec1{width: 400px;}
    .homeScreenSec1Content{width: 100%;}
    .homeScreenTitles{font-size: 50px;}
    
}




@media (max-width:1200px) {

    .homeScreenImg1{
        width: 40%;
        height: auto;
    }

    .youtubeHS{
        width: 100%;
    }

}


@media (max-width:900px) {

    * {    
        /* margin: 0 !important; */
        /* padding: 0 !important; */
    }


    .homeScreenImg1{width: 70%; height: auto; margin-left: 0px;}
    .homeScreen{padding: 0px; width: 100%;}
    .homeScreenContainer{max-width: 100%;}

    .homeScreenSec1{
        flex-direction: column-reverse;
        
    }

    .homeScreenSec2Content{
        margin-top: 60px;
        margin-bottom: 100px;
        font-size: 27px; 
        padding: 20px; 
        box-sizing: border-box;
    }

    .homeScreenTitles{font-size: 37px;}
    .homeScreenSubtitle{font-size: 15px;}

    .homeScreenContact{
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
    }

    

    .homeScreenContactInput{width: 95%;}
    .homeScreenContactBtn{width: 95%; height: 50px;}

    .homeScreenSec3Content{
        align-items: center; 
        margin-right: 0px; 
        max-width: 90%; 
        margin-top: -30px;
        font-size: 17px;
        text-align: left;
    }

    .youtubeHS{width: 100%; height: 250px;}


    .homeScreenSec3Btn{display: none;}

    .homeScreenSec3Mobile{
        display: flex;
        justify-content: center;
        gap: 5px;
        width: 100%;
    }

    .homeScreenSec3BtnMobile{
        height: 50px;
        border-radius: 16px;
        border-color: #01A4BD;
        color: #01A4BD;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        border-style: solid;
    }

    .homeScreenSec3BtnMobile:hover{
        background-color: #01A4BD;
        color: white;
    }


    .homeScreenSec4{
        flex-direction: column; 
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .homeScreenImg2{width: 80%;}
    .homeScreenSec4Titles{font-size: 37px;}
    .homeScreenExploreFeatures{font-size: 25px;}

    .homeScreenSec5{
        flex-direction: column; 
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .hssNumber{font-size: 24px;}
    .hssTitle{text-align: left; font-size: 24px;}

    .homeScreenPlanRow{flex-direction: column; align-items: center; }
    .planTileSection{width: 96%; align-items: center;}
    
    .homeScreenSec6Titles{font-size: 37px;}
    .homeScreenSec6{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .planTile{max-width: 100%; text-align: left;}
    .planTileContent{flex-direction: column; gap: 20px; }
    .planTileLine{display: none;}
    .planTileCost{font-size: 60px;}
    

    .homeScreenSec7Titles{font-size: 32px;}

    .homeScreenSec7Btn{
        width: 270px;
        height: auto;
        padding: 10px;
        font-size: 27px;
    }
    
}