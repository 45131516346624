
.paymentShadow{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: #00000080;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.paymentPopUp{
    margin-bottom: 200px;
    width: 635px;
    /* height: 575px; */
    background-color: white;
    border-radius: 12px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.paymentRightAlign{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
}


.paymentPopUpCancel{
    cursor: pointer;
    height: 24px;
}



.paymentPopUpSubmit{
    height: 48px;
    width: 125px;
    border-radius: 12px;

    background-color: #01A4BD;
    border-color: transparent;
    color: white;
    cursor: pointer;
}


.paymentPopUpSubmit:disabled{
    background-color: lightgray;
    cursor: auto;
}

.paymentSectionBPU
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.paymentComponentBPU{
    height: 269px;
}


.successfulPayment{
    width: 100%;
    height: 269px;
    background-color: #EFFAFC;
    border-color: #01A4BD;
    color: #01A4BD;
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 18px;
}
