.contactScreen{
    padding: 60px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    align-items: center;
}

.contactScreenContainer{
    max-width: 1500px;
    display: flex;
    flex-direction: row;    
    align-items: top;
    justify-content: space-between;
    width: 80%;
    /* width: 100%; */
    gap: 20px;
    
}


.contactImg{
    /* height: 580px;
    width: 387px; */
    width: 25%;
    margin-top: 30px;
}


.contactColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 45px;
    background-color: #fff;
}

.contactTitle{
    font-size: 60px;
    font-weight: 400;
    text-align: left;
}

.contactInputs{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    gap: 30px;
}

.contactInputSubmitted{
    max-width: 378px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 33px;

    background-color: #ECF6FF;
    border-radius: 14px;

    color: black;
    font-size: 19px;
    font-weight: 400;
}

.contactSubmittedImg{
    height: 81px;
    width: 81px;
}


.inputContact{
    -webkit-appearance: none;
    border: none;
    outline: none;

    width: 300px;
    border-color: transparent;
    border-bottom: black;
    border-width: 1px;
    border-style: solid;
    padding: 10px;

    font-size: 18px;
    font-weight: 300;
}

.inputContact.error{
    border-bottom: red;
    border-width: 1px;
    border-style: solid;
}

.inputContact::placeholder
{
    color: black;
}

.inputContact.error::placeholder{
    color: red;
}


.contactButton{
    margin-left: 10px;
    width: 163px;
    height: 46px;
    background-color: #01A4BD;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border-radius: 52px;
    border-color: transparent;
    cursor: pointer;
}


.contactColumn2{
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-size: 19px;
    font-weight: 300;
    margin-top: 110px;
    
}

.contactSubTitle{
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
}

.contactRow{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 20px;
}

.contactIcon{
    height: 21px;
    width: 21px;
    cursor: pointer;
}


@media (max-width:900px) {

    .contactScreen{
        padding: 20px;
    }

    .contactScreenContainer
    {
        flex-direction: column;
        width: 90%;
        gap: 0px;
    }
    .contactImg{display: none;}
    .contactColumn2{margin-top: 20px;}
    .contactTitle{font-size: 50px;}
}