.dashboardScreen{
    padding: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    
    align-items: center;
}

.dashboardScreenContainer{
    max-width: 1500px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    width: 90%;
    /* background-color: #01A4BD; */
}


.dashboardSection1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    
}

.dashSec1Column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 12px;

    color: #858797;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

}

.dashboardTitle{
    color: black;
    font-weight: 600;
    font-size: 36px;
}

.dashboardImage{
    height: 160px;
    width: 425px;
}


.upcomingClasses{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.dashboardSubTitle{
    font-weight: 600;
    font-size: 29px;
}


.classesTileGrid{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 50px;
    /* background-color: red; */
    max-width: 100%;
}


.classesTileColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
}


.timezoneSpan{
    font-weight: 400;
    font-size: 16px;
    color: #808080;
}

/* ----------------------------------------------------- */

.classTile{
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    
}

.classTitle{
    font-weight: 500;
    font-size: 18px;
}


.classTitle.blue{
    color: #1A55AF;
}

.classTitle.green{
    color: #3E9483;
}

.classMainTile{
    /* height: 140px; */
    width: 100%;
    border-radius: 17px;
    
    border-color: transparent;
    background-color: #ECF6FF;
    color: #1A55AF;
    padding: 17px;
    
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.classMainTile.green{
    background-color: #6CBBAB38;
    color: #3E9483;
}

.classTileRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.classTileInnerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 13px;
    gap: 18px;
}

.classTileIcon{
    height: 56px;
    width: 56px;
}

.classTileColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.classTileTitle{
    font-weight: 600;
    font-size: 22px;
}

.classTileSubtitle{
    font-weight: 300;
    font-size: 15px;
}


.classDots{
    display: flex;
    flex-direction: row;
    gap: 3px;
    cursor: pointer;
}

.classDot{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    border-color: #1A55AF;
    border-width: 2px;
    border-style: solid;
}

.classDot.green{
    border-color: #4AAB98;
}

.classTileTime{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-left: 5px;
}

.classTimeIcon{
    height: 18px;
    width: 18px;
}

.classTimeText{
    text-align: left;
    font-weight: 600;
    font-size: 15px;
}

.classTileBtn{
    width: 113%;
    height: 57px;
    border-radius: 18px;

    font-weight: 600;
    font-size: 16px;
    color: #1A55AF;
    background-color: white;
    text-align: left;
    padding-left: 28px;

    border-color: #1A55AF;
    border-width: 2px;
    border-style: solid;

    transition: all 0.3s;
}

.classTileBtn:hover{
    background-color: #1A55AF;
    color: white;
}


.classTileBtn.green{
    border-color: #4AAB98;
    color: #3E9483;
}

.classTileBtn.green:hover{
    background-color: #4AAB98;
    color: white;
}




/* ----------------------------------------------------- */


.noClasses{
    padding: 29px;
    height: 200px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    border-radius: 17px;
    border-color: transparent;
    background-color: #ECF6FF;
}

.noClassesTitle{
    font-weight: 600;
    font-size: 35px;
}

.noClassesSubTitle{
    font-weight: 400;
    font-size: 16px;
    color: #858797;
}

.noClassesBtns{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.noClassesBtn{
    height: 48px;
    background-color: #63CFE51A;
    border-radius: 12px;
    border-color: transparent;

    font-size: 16px;
    font-weight: 500;
    color: #01A4BD;
    cursor: pointer;
}





.classBalanceSection{
    width: 389px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}


.classBalanceTile{
    width: 100%;
    height: 105px;

    background-color: #ECF6FF;
    border-radius: 17px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 26px;
    gap: 19.5px;
}

.classBalanceImg{
    height: 56px;
}

.classBalanceColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:5px;
    color: #1A55AF;
    font-weight: 300;
    font-size: 15px;
}

.classBalanceTitle{
    font-weight: 600;
    font-size: 22px;
}

.classBalanceBtn{
    width: 100%;
    height: 36px;
    border-color: #1A55AF;
    border-style: solid;
    background-color: white;
    border-radius: 8px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #1A55AF;
    font-weight: 500;
    font-size: 16px;
    padding-left: 26px;
    cursor: pointer;
    transition: all 0.3s;
}


.classBalanceBtn:hover{
    background-color: #1A55AF;
    color: white;
}